import "./FrameComponent1.css";

const FrameComponent1 = () => {
  return (
    <section className="macbook-pro-16-2-child">
      <div className="frame-parent2">
        <div className="frame-wrapper">
          <div className="frame-parent3">
            <div className="frame-wrapper1">
              <div className="cylero-1-parent">
                <img
                  className="cylero-1-icon"
                  loading="eager"
                  alt=""
                  src="/cylero-1@2x.png"
                />
                <h1 className="purple-heart">
                  <p className="purple">PURPLE</p>
                  <p className="heart">HEART</p>
                </h1>
              </div>
            </div>
            <div className="ellipse-div" />
          </div>
        </div>
        <div className="wrapper-group-1">
          <img
            className="wrapper-group-1-child"
            loading="eager"
            alt=""
            src="/group-1.svg"
          />
        </div>
        <div className="frame-wrapper2">
          <div className="frame-parent4">
            <div className="wanna-celebrate-this-valentine-wrapper">
              <h1 className="wanna-celebrate-this">
                Wanna celebrate this valentine’s with your college crush?
              </h1>
            </div>
            <div className="frame-child6" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FrameComponent1;
