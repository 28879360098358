// import ThirdEmailInput from "./ThirdEmailInput";
// import "./MacBookPro16.css";

// function EmailInput({ placeholder }) {
//   return <input className="email-input" placeholder={placeholder} type="text" />;
// }

// const MacBookPro163 = () => {
//   return (
//     <header className="macbook-pro-16-31">
//       <div className="first-ellipse" />
//       <div className="first-ellipse1" />
//       <div className="second-ellipse" />
//       <img
//         className="image-1-icon3"
//         loading="eager"
//         alt=""
//         src="/image-1@2x.png"
//       />
//       <div className="email-text-frames">
//         <div className="email-text-frames-child" />
//         <div className="your-iith-email1">
//         <img
//                     className="second-crush-child"
//                     alt=""
//                     // src="/line-10.svg"
//                   />
//         <input
//                     className="crush-2-email1"
//                     placeholder="IITH email"
//                     type="text"
//                   />
//         </div>
//         <div className="vector-parent">
//           <img
//             className="frame-child1"
//             loading="eager"
//             alt=""
//             src="/line-10.svg"
//           />
//           <div className="third-email-input-parent">
//             <ThirdEmailInput />
//             <div className="frame-parent1">
//               <div className="second-crush-parent">
//                 <div className="second-crush">
//                   <img
//                     className="second-crush-child"
//                     alt=""
//                     src="/line-10.svg"
//                   />
//                   <input
//                     className="crush-2-email1"
//                     placeholder="Crush 2 email"
//                     type="text"
//                   />
//                 </div>
//                 {/* <img className="frame-child2" alt="" src="/line-10.svg" /> */}
//               </div>
//               <div className="crush-3-email1">
//               <img
//                     className="second-crush-child"
//                     alt=""
//                     // src="/line-10.svg"
//                   />
//                   <input
//                     className="crush-2-email1"
//                     placeholder="Crush 3 email"
//                     type="text"
//                   />
//               </div>
//             </div>
//             <div className="vector-group">
//               <img
//                 className="frame-child3"
//                 loading="eager"
//                 alt=""
//                 src="/line-10.svg"
//               />
//             <div className="crush-4-email1">
//               <img
//                     className="second-crush-child"
//                     alt=""
//                     // src="/line-10.svg"
//                   />
//                   <input
//                     className="crush-2-email1"
//                     placeholder="Crush 4 email"
//                     type="text"
//                   />
//               </div>            </div>
//             <div className="vector-container">
//               <img
//                 className="frame-child4"
//                 loading="eager"
//                 alt=""
//                 src="/line-10.svg"
//               />
//             <div className="crush-5-email1">
//               <img
//                     className="second-crush-child"
//                     alt=""
//                     // src="/line-10.svg"
//                   />
//                   <input
//                     className="crush-2-email1"
//                     placeholder="Crush 5 email"
//                     type="text"
//                   />
//               </div>           
//             </div>
//             <img
//               className="frame-child5"
//               loading="eager"
//               alt=""
//               src="/line-10.svg"
//             />
//           </div>
//         </div>
//       {/* <div className="vector-parent">
//             <img className="frame-child1" loading="eager" alt="" src="/line-10.svg" />
//             <div className="third-email-input-parent">
//               <EmailInput placeholder="Crush 1 email" />
//               <div className="frame-parent1">
//                 <div className="second-crush-parent">
//                   <div className="second-crush">
//                     <img className="second-crush-child" alt="" src="/line-10.svg" />
//                     <EmailInput placeholder="Crush 2 email" />
//                   </div>
//                   <img className="frame-child2" alt="" src="/line-10.svg" />
//                 </div>
//                 <EmailInput placeholder="Crush 3 email" />
//               </div>
//               <div className="vector-group">
//                 <img className="frame-child3" loading="eager" alt="" src="/line-10.svg" />
//                 <EmailInput placeholder="Crush 4 email" />
//               </div>
//               <div className="vector-container">
//                 <img className="frame-child4" loading="eager" alt="" src="/line-10.svg" />
//                 <EmailInput placeholder="Crush 5 email" />
//               </div>
//               <img className="frame-child5" loading="eager" alt="" src="/line-10.svg" />
//             </div>
//           </div> */}

          

//         <button className="rectangle-parent">
//           <div className="rectangle-div" />
//           <div className="submit">submit! 💜</div>
//         </button>
//       </div>
//     </header>
//   );
// };

// export default MacBookPro163;




import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import React, { useState } from 'react';
import './MacBookPro16.css';
import {
  getDatabase,
  get,
  set,
  ref,
  child,
} from "https://www.gstatic.com/firebasejs/10.8.0/firebase-database.js";

const firebaseConfig = {
  apiKey: "AIzaSyAsDMc6_QWl0_PyEPXdmV7QGmghz_1yZEo",
  authDomain: "purple-heart-9f8c6.firebaseapp.com",
  databaseURL: "https://purple-heart-9f8c6-default-rtdb.firebaseio.com",
  projectId: "purple-heart-9f8c6",
  storageBucket: "purple-heart-9f8c6.appspot.com",
  messagingSenderId: "707339102164",
  appId: "1:707339102164:web:478bccedbec285af18d841",
  measurementId: "G-0X1KE8HX0J",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const EmailInput = ({ placeholder, id, value, onChange }) => {
  return <input className="crush-2-email1" placeholder={placeholder} id={id} value={value} onChange={onChange} />;
};

const MacBookPro163 = () => {
  const [formData, setFormData] = useState({
    username: '',
    P1: '',
    P2: '',
    P3: '',
    P4: '',
    P5: '',
    P6: ''
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "user"), formData);
      alert("Saved Successfully !");
      // Clear form fields after successful submission
      setFormData({
        username: '',
        P1: '',
        P2: '',
        P3: '',
        P4: '',
        P5: '',
        P6: ''
      });
    } catch (error) {
      console.error("Error writing data:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <header className="macbook-pro-16-31">
      <div className="first-ellipse" />
      <div className="first-ellipse1" />
      <div className="second-ellipse" />
      <img
        className="image-1-icon3"
        loading="eager"
        alt=""
        src="/image-1@2x.png"
      />
      <div className="email-text-frames">
        <div className="email-text-frames-child" />
        
        <EmailInput placeholder="IITH email" id="username" value={formData.username} onChange={handleChange} />
        <div className="vector-parent">
          <img
            className="frame-child1"
            loading="eager"
            alt=""
            src="/line-10.svg"
          />
          <div className="third-email-input-parent">
            <EmailInput placeholder="P1" id="P1" value={formData.P1} onChange={handleChange} />
            <EmailInput placeholder="P2" id="P2" value={formData.P2} onChange={handleChange} />
            <EmailInput placeholder="P3" id="P3" value={formData.P3} onChange={handleChange} />
            <EmailInput placeholder="P4" id="P4" value={formData.P4} onChange={handleChange} />
            <EmailInput placeholder="P5" id="P5" value={formData.P5} onChange={handleChange} />
            <EmailInput placeholder="P6" id="P6" value={formData.P6} onChange={handleChange} />
            <button className="rectangle-parent" onClick={handleSubmit}>
              <div className="rectangle-div" />
              <div className="submit">submit! 💜</div>
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default MacBookPro163;
