import { useMemo } from "react";
import "./FrameNameAam.css";

const FrameNameAam = ({
  yourIithEmail,
  xxmtech11001iithacin,
  propHeight,
  propPadding,
  propMinHeight,
}) => {
  const frameNameAamStyle = useMemo(() => {
    return {
      height: propHeight,
      padding: propPadding,
      minHeight: propMinHeight,
    };
  }, [propHeight, propPadding, propMinHeight]);

  return (
    <div className="frame-name-aam" style={frameNameAamStyle}>
      <div className="frame-name-bbm">
        <h2 className="your-iith-email2">{yourIithEmail}</h2>
        <button className="text-input-xmx">
          <div className="text-input-xmx-child" />
          <div className="xxmtech11001iithacin">{xxmtech11001iithacin}</div>
        </button>
      </div>
    </div>
  );
};

export default FrameNameAam;
