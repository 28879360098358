import { useMemo } from "react";
import "./FrameComponent.css";

const FrameComponent = ({
  xxmtech11001iithacin,
  propPadding,
  propHeight,
  propDisplay,
}) => {
  const frameDivStyle = useMemo(() => {
    return {
      padding: propPadding,
    };
  }, [propPadding]);

  const xxmtech11001iithacinStyle = useMemo(() => {
    return {
      height: propHeight,
      display: propDisplay,
    };
  }, [propHeight, propDisplay]);

  return (
    <div className="cruh-crushes-list-arrow-wrapper" style={frameDivStyle}>
      <button className="cruh-crushes-list-arrow">
        <div className="cruh-crushes-list-arrow-child" />
        <div
          className="xxmtech11001iithacin1"
          style={xxmtech11001iithacinStyle}
        >
          {xxmtech11001iithacin}
        </div>
      </button>
    </div>
  );
};

export default FrameComponent;
