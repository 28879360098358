import FrameNameAam from "./FrameNameAam";
import FrameComponent from "./FrameComponent";
import "./ValentineFrame.css";

const ValentineFrame = () => {
  return (
    <section className="valentine-frame">
      <div className="sub-cylinder-frame">
        <div className="group-frame">
          <div className="text-input-frame">
            <h1 className="how-it-works">How it works?</h1>
          </div>
          <div className="text-input-list">
            <div className="frame-parent5">
              <div className="you-will-fill-this-parent">
                <h1 className="you-will-fill">You will fill this:</h1>
                <FrameNameAam
                  yourIithEmail="your iith email:"
                  xxmtech11001iithacin="xxmtech11001@iith.ac.in"
                />
                <div className="text-input-crush">
                  <div className="frame-column-aab-aam-bbm">
                    <h2 className="your-crushes-list">your crushes list:</h2>
                    <div className="arrow-connect">
                      <button className="line-separator">
                        <div className="line-separator-child" />
                        <div className="aabtech11001iithacin">
                          aabtech11001@iith.ac.in
                        </div>
                      </button>
                      <button className="line-separator1">
                        <div className="line-separator-item" />
                        <div className="aamtech11002iithacin">
                          aamtech11002@iith.ac.in
                        </div>
                      </button>
                      <button className="line-separator2">
                        <div className="line-separator-inner" />
                        <div className="bbmtech11003iithacin">
                          bbmtech11003@iith.ac.in
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-your-input">
                <h1 className="your-crush-will">Your crush will fill this:</h1>
                <FrameNameAam
                  yourIithEmail="your crush’s email:"
                  xxmtech11001iithacin="aamtech11002@iith.ac.in"
                  propHeight="unset"
                  propPadding="0rem var(--padding-6xl) 0rem 0rem"
                  propMinHeight="10.31rem"
                />
                <div className="xxm-techiithacinffb-techiithac">
                  <div className="ggm-techiithacin-parent">
                    <div className="ggm-techiithacin">
                      <h2 className="your-crushs-crushes">
                        your crush’s crushes list:
                      </h2>
                      <img
                        className="ggm-techiithacin-child"
                        alt=""
                        src="/arrow-1.svg"
                      />
                      <div className="cylinder-with-arrows">
                        <img className="cylinder-with-arrows-child" alt="" />
                        <img
                          className="cylero-3-icon"
                          loading="eager"
                          alt=""
                          src="/cylero-3@2x.png"
                        />
                      </div>
                    </div>
                    <FrameComponent xxmtech11001iithacin="xxmtech11001@iith.ac.in" />
                    <FrameComponent
                      xxmtech11001iithacin="ffbtech11002@iith.ac.in"
                      propPadding="0rem var(--padding-7xs) 0rem 0rem"
                      propHeight="unset"
                      propDisplay="inline-block"
                    />
                    <button className="rectangle-group">
                      <div className="frame-child7" />
                      <div className="ggmtech11003iithacin">
                        ggmtech11003@iith.ac.in
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="blank-frame-wrapper">
              <div className="blank-frame">
                <div className="empty-frame">
                  <button className="email-input">
                    <div className="email-input-child" />
                    <div className="ccmtech11004iithacin">
                      ccmtech11004@iith.ac.in
                    </div>
                  </button>
                  <div className="blank-frame1">
                    <p className="p">.</p>
                    <p className="p1">.</p>
                    <p className="p2">.</p>
                    <p className="p3">.</p>
                    <p className="p4">.</p>
                  </div>
                </div>
                <div className="arrow-line">
                  <p className="p5">.</p>
                  <p className="p6">.</p>
                  <p className="p7">.</p>
                  <p className="p8">.</p>
                  <p className="p9">.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="gift-box-frame">
          <div className="list-frame" />
        </div>
      </div>
    </section>
  );
};

export default ValentineFrame;
