import FrameComponent1 from "../components/FrameComponent1";
import ValentineFrame from "../components/ValentineFrame";
import { Link } from "react-router-dom"; 
import "./MacBookPro16.css";

const MacBookPro16 = () => {
  return (
    <div className="macbook-pro-16-2">
      <div className="main-frame" />
      <FrameComponent1 />
      <div className="empty-continer"></div>
     
     <div>
      <Link to="/crush-list">
          
              <button className="exclusive-button">Exclusive for IITH</button>
            
          
      </Link>
      </div>
      <section className="macbook-pro-16-2-inner">
        <div className="wrapper-cylero-2-parent">
          <div className="wrapper-cylero-2">
            <img
              className="cylero-2-icon"
              loading="eager"
              alt=""
              src="/cylero-2@2x.png"
            />
          </div>
          <h1 className="we-got-you">We got you covered!</h1>
        </div>
      </section>
      <ValentineFrame />
    </div>
  );
};

export default MacBookPro16;
